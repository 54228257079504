import App from '../components/App';
import React from 'react';
import {useApp} from '../lib/useApp';

const Index = () => {
  const href = typeof window !== 'undefined' ? window.location.href : '';
  const publicRegex = /^.*\/lairs\/.*\/public\/*/;
  const regexMatch = href.match(publicRegex);
  const isPublic = regexMatch && regexMatch.index === 0;
  const isAppReadyAndIsLoggedIn = useApp({isPublic});

  return (
    <App isAppReadyAndIsLoggedIn={isAppReadyAndIsLoggedIn} isIndexPage={true} />
  );
};
export default Index;
